let Order = function (tokenClient, serviceAddr) {
    let TokenClient = tokenClient;
    let ServiceBaseAddress = serviceAddr;

    /**
     * 获取订单列表
     */

    this.OrderList = function (organizationId, pageIndex, state,keyWord ,pageSize,success, error) {
        var url = ServiceBaseAddress + '/api/OrderRecord/Manager';
        if (organizationId) {
            url += `/${organizationId}`;
        }
        if (pageIndex) {
            url += `/${pageIndex}`;
        }
        if (keyWord ) {
            url += `/${keyWord }`;
        }else {
            url += `/%20`;
        }
        if (pageSize) { 
            url += `/${pageSize}`
        }
        if (state) {
            url += `/${state}`;
        }
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
    /**
     * 获取积分订单列表
     */

    this.IntegralOrderList = function (organizationId, pageIndex, state,keyWord ,pageSize,success, error) {
        var url = ServiceBaseAddress + '/api/OrderRecord/ManagerIntegral';
        if (organizationId) {
            url += `/${organizationId}`;
        }
        if (pageIndex) {
            url += `/${pageIndex}`;
        }
        if (keyWord ) {
            url += `/${keyWord }`;
        }else {
            url += `/%20`;
        }
        if (pageSize) { 
            url += `/${pageSize}`
        }
        if (state) {
            url += `/${state}`;
        }
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
    /**
     * 总后台--获取所有商家订单列表
     * @param pageIndex
     * @param state
     * @param success
     * @param error
     * @constructor
     */
    this.AllMerchantOrder = function ( pageIndex, state, success, error) {
        var url = ServiceBaseAddress + '/api/OrderRecord/Manager/AllMerchant';
        if (pageIndex) {
            url += `/${pageIndex}`;
        }
        if (state) {
            url += `/${state}`;
        }else {
            url += `/`;
        }
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }

    /**
     * 修改地址
     * @param orderRecordId
     * @param item
     * @param success
     * @param error
     */
    this.changeAddress = function (orderRecordId, item, success, error) {
        if (orderRecordId) {
            var url = ServiceBaseAddress + '/api/OrderRecord/Manager/ChangeAddress/'+ orderRecordId;
            TokenClient.Post(url, true, { 'Content-Type': 'application/json' }, item,
                function (data) {
                    if (success) {
                        var jsonObj = JSON.parse(data);
                        success(jsonObj);
                    }
                }, error);
        }
    }

    /**
     * 修改运费
     * @param orderRecordId
     * @param item
     * @param success
     * @param error
     */
    this.ChangeFreight = function (orderRecordId , item, success, error) {
        if (orderRecordId) {
            var url = ServiceBaseAddress + '/api/OrderRecord/Manager/ChangeFreight/'+ orderRecordId;
            TokenClient.Post(url, true, { 'Content-Type': 'application/json' }, item,
                function (data) {
                    if (success) {
                        var jsonObj = JSON.parse(data);
                        success(jsonObj);
                    }
                }, error);
        }
    }

    /**
     * 发货
     * @param orderRecordId
     * @param item
     * @param success
     * @param error
     * @constructor
     */
    this.DeliverGoods = function ( item, success, error) {
        var url = ServiceBaseAddress + '/api/OrderRecord/Manager/DeliverGoods';
        TokenClient.Post(url, true, { 'Content-Type': 'application/json' }, item,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }

    /**
     * 订单详情
     * @param orderId
     * @param success
     * @param error
     * @constructor
     */
    this.OrderDetail = function (orderId, success, error) {
        var url = ServiceBaseAddress + '/api/OrderRecord/Manager/Detail/'+ orderId;
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
    /**
     * 物流信息
     * @param expressCode
     * @param success
     * @param error
     * @constructor
     */
     this.ExpressQuery = function (expressCode, success, error) {
        var url = ServiceBaseAddress + '/api/Commodity/ExpressQuery/'+ expressCode;
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
    /**
     * 商品交易管理
     * @param orderId
     * @param success
     * @param error
     * @constructor
     */
    this.ManagerPayPages = function (pageIndex, state, success, error) {
        var url = ServiceBaseAddress + '/api/OrderRecord/ManagerPayPages';
        if (pageIndex) {
            url += `/${pageIndex}`;
        }
        if (state) {
            url += `/${state}`;
        }else {
            url += `/`;
        }
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
    /**
     * 机构后台--获取该机构评价列表
     * @param keyWord
     * @param pageIndex
     * @param success
     * @param error
     * @constructor
     */
     this.OrderEvaluateList = function (keyWord,pageIndex, success, error) {
        var url = ServiceBaseAddress + '/api/Evaluate/Org';
        if (keyWord ) {
            url += `/${keyWord }`;
        }else {
            url += `/%20`;
        }
        if (pageIndex) {
            url += `/${pageIndex}`;
        }
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
    /**
     * 评论
     * @param item
     * @param success
     * @param error
     * @constructor
     */
     this.OrderEvaluateReplay = function ( item, success, error) {
        var url = ServiceBaseAddress + '/api/Evaluate/Reply';
        TokenClient.Post(url, true, { 'Content-Type': 'application/json' }, item,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }

}
export { Order }
